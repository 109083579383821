import React from "react";
import {DomainContent} from './domain';
const DomainId = DomainContent();
export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
            <div className="row">
            <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/loc.png" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="section-title">
              <h2>Contact Info</h2>
              </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
          &copy;2024 北京且追科技有限公司 QIEZHUI.TECH All Rights Reserved. &nbsp;
          <a href="http://beian.miit.gov.cn" rel="nofollow">
          京ICP备2024060762号-{DomainId}
          </a>
          </p>
        </div>
      </div>
    </div>
  );
};
